<template>
	<div
		v-if="!isShowSomme"
		class="timeline-cursor"
		ref="headerRowTable"
		:style="{
			width: isFixedColumn ? `${sizeCell + 50}px !important` : this.changePourcent ? '100%' : `${cellWidthResponsive}px`,
			minWidth: `${widthCell}px`,
			maxWidth: `${600}px`
		}"
	>
		<template>
			<div v-if="isFixedStart" class="w-100">
				<div class="d-flex flex-row align-items-center justify-content-center h-50 gap-10">
					<div style="width: 100%">{{ FormMSG(1, 'Member of team') }}</div>
					<div>
						<button id="tooltip-target-7899456" class="btn-transparent text-color-rhapsody-in-blue" @click="onAddUsers">
							<component :is="getLucideIcon(ICONS.PLUS_CIRCLE_USER.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="18" />
						</button>
						<b-tooltip custom-class="v-bootstrap-new-user-tooltip" target="tooltip-target-7899456" triggers="hover" placement="top">
							{{ FormMSG(2, 'Add new user') }}
						</b-tooltip>
					</div>
				</div>
				<div class="d-flex flex-row align-items-center justify-content-center h-50">
					<div class="active-filter-custom" style="width: 100%">
						<treeselect
							:options="mapCustomFilter"
							:value="selectedFilter"
							:placeholder="FormMSG(3, 'Select filter')"
							@select="handleChangeFilter"
							:clearable="false"
						>
							<div slot="value-label" slot-scope="{ node }" :title="node.label">
								<div class="treeselect-label">{{ showLimitText(node.label) }}</div>
							</div>
							<div class="d-flex align-items-center justify-content-center" slot="option-label" slot-scope="{ node }">
								<div class="label-select treeselect-label" :title="node.label">{{ node.label }}</div>
							</div>
						</treeselect>
						<div v-if="checkTypeFilter" class="clearable-custom">
							<button @click="onClearFilter" v-b-tooltip.hover.bottom.html="FormMSG(9, 'Clear filter')">x</button>
						</div>
					</div>
					<div
						v-if="selectedFilter === 3"
						@click="handleActiveAction"
						v-b-tooltip.hover.bottom.html="FormMSG(4, 'Active the last action filter')"
						class="timeline-cursor"
					>
						<component :is="getLucideIcon('GripVertical')" :size="20" :stroke-width="2.25" />
					</div>
				</div>
			</div>
			<div :id="`cell-item-${index}`" v-else class="cursor-active">
				<div class="d-flex flex-row justify-content-end w-100">
					<div class="btn-hide-cell-header">
						<button class="btn-close-hide-header" @click="hideColumn(item)" :id="`popover-${index}`">x</button>
						<b-popover custom-class="v-bootstrap-popover-header-column" :target="`popover-${index}`" placement="right" triggers="hover focus">{{
							getTextHideColumn(item)
						}}</b-popover>
					</div>
				</div>
				<div>{{ item.label }}</div>
				<div class="d-flex flex-row justify-content-center">
					<div
						:class="{
							'header-flag-status': true,
							'gris-default': setTimeLocation().active,
							'gris-net': !setTimeLocation().active
						}"
						v-b-tooltip.hover.bottom.html="setTimeLocation().active ? handleShowLocationTooltip() : FormMSG(10, 'Add location')"
					>
						<span v-if="setTimeLocation().active" class="custom-text">{{ setTimeLocation().message }}</span>
						<div class="show-edit-flag" @click="handleOpenFlagHeader()">
							<component :is="getLucideIcon(ICONS.CHECK_SQUARE.name)" color="#06263e" :size="16" />
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
	<div
		v-else
		class="timeline-cursor"
		:style="{ width: showVerified ? `${cellWidthResponsive}px !important` : '', minWidth: showVerified ? `${widthCell + 38}px` : '' }"
	>
		<template>
			<div v-if="showVerified" class="d-flex flex-column justify-content-center w-100 transition-collapse-rt-lf">
				<div class="d-flex flex-row justify-content-center">{{ FormMSG(11, 'Somme') }}</div>
				<!-- <div v-if="showVerified" class="d-flex flex-column justify-content-center"> -->
				<div class="d-flex flex-row justify-content-center">
					<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
						<component :is="getLucideIcon(ICONS.CHEVRONS_RIGHT.name)" :color="ICONS.CHEVRONS_RIGHT.color" :size="16" />
					</button>
				</div>
				<!-- </div>
				<div v-else class="d-flex flex-row justify-content-center">
					<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
						<component :is="getLucideIcon(ICONS.CHEVRONS_LEFT.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="16" />
					</button>
				</div> -->
			</div>
			<div v-else class="d-flex flex-column w-100 transition-collapse-lf-rt" style="display: flex; background-color: #78807a">
				<div class="d-flex flex-row justify-content-start">
					<button class="btn-transparent text-color-rhapsody-in-blue" @click="handleShowContentVerified">
						<component :is="getLucideIcon(ICONS.CHEVRONS_LEFT.name)" :color="ICONS.CHEVRONS_LEFT.color" :size="16" />
					</button>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import globalMixin from '@/mixins/global.mixin';
import * as _ from 'lodash';

export default {
	name: 'HeaderRowTables',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	components: { Treeselect },
	props: {
		isProduction: {
			type: Boolean,
			required: false,
			default: false
		},
		handleAddNewUser: {
			type: Function,
			required: false
		},
		initCustomFilter: {
			type: Boolean,
			required: false
		},
		customFilterValue: {
			type: Number,
			required: false
		},
		isFixedStart: {
			type: Boolean,
			required: false
		},
		item: {
			type: Object,
			required: false,
			default: () => ({})
		},
		onActiveHideColumn: {
			type: Function,
			required: false
		},
		onOpenFlagHeader: {
			type: Function,
			required: false
		},
		onHandleShowContentVerified: {
			type: Function,
			required: false
		},
		isShowSomme: {
			type: Boolean,
			required: false
		},
		showVerified: {
			type: Boolean,
			required: false
		},
		index: {
			type: Number,
			required: false
		},
		widthCell: {
			type: Number,
			required: false
		},
		sizeCell: {
			type: Number,
			required: false
		},
		isFixedColumn: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data: () => ({
		selectedFilter: 0,
		// resizeWidth: 0,
		changePourcent: false
	}),
	watch: {
		selectedFilter: {
			handler(newVal) {
				const element = document.getElementById(`cell-item-${this.index}`);
				if (element) {
					const { width } = element.parentNode.parentNode.getBoundingClientRect();
					const _changePourcent = this.sizeCell + 300;
					if (width > _changePourcent) {
						this.changePourcent = true;
					} else {
						this.changePourcent = false;
					}
					// this.resizeWidth = width - this.sizeCell;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		customFilterValue: {
			handler(newVal) {
				this.selectedFilter = +newVal;
				return newVal;
			},
			immediate: true,
			deep: true
		},
		initCustomFilter: {
			handler(newVal) {
				if (newVal) {
					this.selectedFilter = 0;
					const item = {
						type: false,
						value: this.selectedFilter
					};
					this.$emit('handle-reset-init-custom-filter', item);
				}
				return newVal;
			},
			immediate: true,
			deep: true
		},
		showVerified: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		cellWidthResponsive() {
			if (!this.isFixedColumn) {
				return this.sizeCell;
			}
			return this.widthCell;
		},
		checkTypeOfHeader() {
			const isFixedStart = this.isFixedStart;
			const isShowSomme = this.isShowSomme;
			if (isShowSomme) {
				return false;
			} else {
				if (isFixedStart) {
					return true;
				}
				return false;
			}
		},
		checkTypeFilter() {
			const response = this.selectedFilter === 0 || _.isNil(this.selectedFilter) ? false : true;
			return response;
		},
		mapCustomFilter() {
			return [
				{ id: 0, label: this.FormMSG(5, 'Select filter'), isDisabled: true },
				{ id: 1, label: this.FormMSG(6, 'Filter by name') },
				{ id: 2, label: this.FormMSG(7, 'Filter by function') },
				{ id: 3, label: this.FormMSG(8, 'Custom filter') }
			];
		}
	},
	methods: {
		showLimitText(text) {
			if (text.length > 18) {
				return text.toString().substr(0, 15) + ' ...';
			}
			return text;
		},
		onAddUsers() {
			this.handleAddNewUser();
		},
		onClearFilter() {
			this.selectedFilter = 0;
			let value = this.selectedFilter;
			this.handleChangeFilter(value);
		},
		handleActiveAction() {
			let value = this.selectedFilter;
			this.handleChangeFilter(value);
		},
		handleChangeFilter(item) {
			if (!_.isNil(item)) {
				const { id } = item;
				if (id !== undefined) {
					this.selectedFilter = +id;
				} else {
					this.selectedFilter = 0;
				}
				this.$emit('filter-handle-user', this.selectedFilter);
			}
		},
		setTimeLocation() {
			const item = this.item;
			if (item.name.toString().length !== 0) {
				return {
					id: item.id,
					active: !!+item.id,
					message: item.name
				};
			}
			return {
				id: 0,
				active: !!0,
				message: this.FormMSG(10, 'OFF')
			};
		},
		getTextHideColumn(item) {
			if (item) {
				const { canNotBeHidden } = item;
				if (canNotBeHidden) {
					return this.FormMSG(13, 'We cannot hide this column because there are tsDays.');
				} else {
					return this.FormMSG(12, 'Hide this column');
				}
			}
			return 'None';
		},
		hideColumn(item) {
			if (item) {
				const { canNotBeHidden } = item;
				if (canNotBeHidden) {
					this.alertModal(
						this.FormMSG(14, 'Confirmation'),
						this.FormMSG(13, 'We cannot hide this column because there are tsDays.'),
						this.FormMSG(15, 'Close'),
						'info',
						'center'
					);
				} else {
					this.onActiveHideColumn(this.item, this.index);
				}
			}
		},
		handleShowLocationTooltip() {
			return this.item.name;
		},
		handleOpenFlagHeader() {
			this.onOpenFlagHeader(this.item);
		},
		handleShowContentVerified() {
			this.onHandleShowContentVerified();
		}
	}
};
</script>
<style lang="scss">
.custom-text {
	display: inline-block;
	width: auto;
	white-space: nowrap;
	overflow: hidden;
	padding: 4px;
	text-overflow: ellipsis;
}
// #headRefLocation {
// 	.btn-hide-cell-header {
// 		display: none;
// 	}
// 	&:hover {
// 		.btn-hide-cell-header {
// 			display: flex;
// 			position: absolute !important;
// 			flex-direction: row;
// 			justify-content: end;
// 			align-items: center;
// 			bottom: 44px;
// 			font-size: 0.8rem;
// 			flex: 1;
// 			.btn-close-hide-header {
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				height: 20px;
// 				width: 20px;
// 				font-weight: 400;
// 				color: #fff;
// 				background: #ea4e2c;
// 				border-color: #ea4e2c;
// 				outline: none;
// 				border-radius: 50%;
// 				border: none;
// 			}
// 		}
// 	}
// }
</style>
<style scoped lang="scss">
.cursor-active {
	display: flex;
	flex-direction: column;
	flex: 1;
	.show-edit-flag {
		display: none;
	}
	&:hover {
		cursor: pointer !important;
		.show-edit-flag {
			display: block;
		}
	}
}
.header-flag-status {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding: 0px 4px 0px 4px;
	color: #06263e;
	width: 100%;
	height: 21px;
	font-size: 12px;
	gap: 4px;
	margin: 0px 2px 0px 2px;
}
.gris-default {
	background-color: #e4dfec;
}
.gris-net {
	background-color: #b4c5cd;
}
.label-select {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
	justify-content: center !important;
	height: 30px;
}

.transition-collapse-rt-lf {
	animation-duration: 0.2s;
	animation-name: slideInRightToleft;
}

.transition-collapse-lf-rt {
	animation-duration: 0.2s;
	animation-name: slideInleftToRight;
}

@keyframes slideInRightToleft {
	from {
		margin-left: 100%;
	}

	to {
		margin-left: 0%;
	}
}

@keyframes slideInleftToRight {
	from {
		margin-left: 0%;
	}

	to {
		margin-left: 100%;
	}
}

.gap-10 {
	gap: 10px;
}
</style>
<style lang="scss">
.active-filter-custom {
	display: flex;
	position: relative;
	margin: 0px 6px 0px 6px;
	.clearable-custom {
		display: flex;
		position: absolute;
		color: black;
		align-items: center;
		flex-direction: row;
		height: 25px;
		margin-left: 76%;
		top: 1px;
		width: 20px;
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			outline: none;
			height: 20px;
			border-color: white;
			margin-right: 20px;
			width: 20px;
			border-radius: 50%;
			border: 2px solid #d3ded6;
			font-weight: 700;
			color: #1c4f4b;
		}
	}
	.vue-treeselect {
		.vue-treeselect__control {
			height: 26px !important;
			border-radius: 8px !important;
			border: 1px solid #00a09c !important;
			.vue-treeselect__value-container {
				display: contents;
				.vue-treeselect__placeholder,
				.vue-treeselect__single-value {
					top: -4px;
				}
				.vue-treeselect__multi-value {
					.vue-treeselect__input-container {
						.vue-treeselect__input {
							height: 10px !important;
						}
					}
				}
			}
			.vue-treeselect__x-container {
				margin-right: 2px;
			}
		}
	}
}
$bg-default-popover: #000 !important;
.v-bootstrap-popover-header-column {
	.arrow::after {
		border-right-color: $bg-default-popover;
		border-left-color: $bg-default-popover;
		opacity: 0.9 !important;
		color: #fff !important;
	}
	.popover-body {
		background-color: $bg-default-popover;
		max-width: 200px !important;
		padding: 0.25rem 0.5rem !important;
		text-align: center;
		border-radius: 0.25rem;
		opacity: 0.9 !important;
		color: #fff !important;
	}
}

.v-bootstrap-new-user-tooltip {
	.arrow {
		top: -45px;
		&::before {
			transform: rotate(180deg) !important;
		}
	}
	.tooltip-inner {
		margin-top: -76px !important;
	}
}
</style>
